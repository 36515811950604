<template>
  <div class="paylater">
    <div class="hero bg-grey w-full relative mdmax:px-4 overflow-hidden">
      <div class="shape-3 inline-block absolute top-0 -left-16 mdmax:hidden">
        <img src="@/assets/images/shape3.png" />
      </div>
      <div class="shape-1 inline-block absolute top-0 -right-14 mdmax:hidden">
        <img src="@/assets/images/shape1.png" />
      </div>

      <div class="container mx-auto">
        <div class="flex justify-between mdmax:my-0 mdmax:flex-col">
          <div class="flex-col w-1/2 h-full mdmax:w-full my-24 mdmax:my-0 mdmax:pt-10 mdmax:ml-0 ml-12">
            <div class="font-extrabold mdmax:font-bold leading-snug text-5xl mdmax:text-3xl mdmax:mb-2 mdmax:w-full text-yellow">
              <span class="text-neutral">Study Now, Pay Later</span>
            </div>
            <div class="text-2xl mdmax:text-base text-neutral w-full leading-relaxed">
              Kesempatan untuk Anda mendapatkan beasiswa, magang, proyek, hingga kesempatan menjadi karyawan tetap di perusahaan-perusahaan yang menjadi mitra G2Academy.
            </div>
          </div>
          <div class="w-1/2 pt-28 mdmax:pt-0 mdmax:w-full h-full">
            <ImageItem :source="require('@/assets/images/loan/snpl.webp')" />
          </div>
        </div>
      </div>
    </div>
    <div class="py-10 bg-white mdmax:px-4">
      <div class="container">
        <div class="text-2xl font-semibold text-center mb-6">Tentang Study Now, Pay Later</div>
        <div class="flex text-center w-full mdmax:inline">
          <div class="w-1/2 mdmax:flex mdmax:w-full mdmax:mb-5">
            <img src="@/assets/images/loan/murid.png" class="m-auto mdmax:w-12" />
            <p class="text-lg mdmax:text-sm mdmax:text-left mdmax:pl-2 mt-2">
              Peserta program tidak perlu khawatir mengenai biaya kursus, karena baru berkewajiban membayar biaya kursus dengan mencicil setelah bekerja.
            </p>
          </div>
          <div class="w-1/2 mdmax:flex mdmax:w-full mdmax:mb-5">
            <img src="@/assets/images/loan/berkas.png" class="m-auto mdmax:w-12" />
            <p class="text-lg mdmax:text-sm mdmax:text-left mdmax:pl-2 mt-2">Jangka waktu cicilan dapat dilakukan selama 12, 15, 18, bahkan 24 bulan.</p>
          </div>
          <div class="w-1/2 px-8 mdmax:flex mdmax:w-full mdmax:mb-5 mdmax:px-0">
            <img src="@/assets/images/loan/komputer.png" class="m-auto mdmax:w-12" />
            <p class="text-lg mdmax:text-sm mdmax:text-left mdmax:pl-2 mt-2">
              Proses melalui pengajuan dan persetujuan dari Pinjaman pendidikan (loan partner). Peserta harus memenuhi syarat dan ketentuan kredit dari Loan Partner.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="py-10 bg-grey mdmax:px-4">
      <div class="container text-center">
        <div class="text-2xl text-center mb-6 font-extrabold">Timeline SNPL</div>
        <a href="https://g2labcms.s3-ap-southeast-1.amazonaws.com/1646654645843-snpl_timeline.png" target="_blank">
          <img src="@/assets/images/loan/snpl_timeline.png" alt="snpl_timeline" class="mx-auto mdmax:w-fulls cursor-pointer" />
        </a>
      </div>
    </div>
    <div class="py-10 bg-white mdmax:px-4">
      <div class="container text-center">
        <div class="text-2xl text-center mb-6 font-extrabold">Payment Plan</div>
        <div class="text-2xl text-center mb-6 font-semibold">SNPL</div>
        <a href="https://g2labcms.s3-ap-southeast-1.amazonaws.com/1646654709959-snpl_payment.png" target="_blank">
          <img src="@/assets/images/loan/snpl_payment.png" alt="snpl_payment" class="mx-auto rounded-lg mdmax:w-auto cursor-pointer" />
        </a>
        <div class="paylater__content--text mt-6">*SNPL: Pembayaran Bulanan sudah termasuk 1.1% – 1.5% Biaya Platform / bulan</div>
      </div>
      <div class="container text-center mt-16">
        <div class="text-2xl text-center mb-6 font-semibold">0% Installment</div>
        <a href="https://g2labcms.s3-ap-southeast-1.amazonaws.com/1646654752678-snpl_installment.png" target="_blank">
          <img src="@/assets/images/loan/snpl_installment.png" alt="snpl_installment" class="mx-auto rounded-lg mdmax:w-auto cursor-pointer" />
        </a>
        <div class="paylater__content--text mt-6">*Cicilan 0%: *Pembayaran bulanan sudah termasuk biaya persetujuan sebesar 3% dikenakan sekali di awal</div>
      </div>
    </div>
    <div class="py-10 bg-grey mdmax:px-4">
      <div class="container">
        <div class="text-2xl font-bold text-center mb-6">Dokumen yang dibutuhkan</div>
        <div class="text-center text-lg mb-10 mdmax:text-sm">Kelengkapan dan kualitas dokumen akan menentukan waktu processing aplikasi</div>
        <div class="flex gap-5 mdmax:inline">
          <div class="bg-white p-4 w-1/2 rounded-lg mdmax:w-full mdmax:mb-4">
            <div class="text-2xl font-bold">ID (KTP) + Selfie from Student & Guardian*</div>
            <div class="text-sm pb-1">*Untuk pelajar berusia 21 tahun ke bawah</div>
            <div class="text-lg pb-1">Guardian Criteria:</div>
            <ul class="text-base list-disc px-7">
              <li>Hubungan keluarga (Saudara Kandung, Orangtua, Paman, Bibi, Ipar, dll)</li>
              <li>Usia 21-55 tahun dan mempunyai income yang dapat diverifikasi</li>
            </ul>
          </div>
          <div class="bg-white p-4 w-1/2 rounded-lg mdmax:w-full mdmax:mb-4">
            <div class="text-2xl font-bold pb-1">Payslip</div>
            <ul class="text-base list-disc px-7">
              <li>untuk pegawai: Payslip / SPT PPh 21 / Mutasi Rekening 1 bulan terakhir</li>
              <li>untuk wiraswasta/professional: Mutasi Rekening 1 bulan terakhir</li>
            </ul>
            <div class="text-lg pb-1">Payslip Criteria:</div>
            <ul class="text-base list-disc px-7">
              <li>Tercantum: Kop Perusahaan, Nama Karyawan, Jabatan, Periode Payslip</li>
              <li>Atau screenshot Mutasi Rekening yang tercantum bukti penerimaan gaji (juga memuat nomor dan nama pemilik rekening)</li>
            </ul>
          </div>
          <div class="bg-white p-4 w-1/2 rounded-lg mdmax:w-full">
            <div class="text-2xl font-bold pb-1">Invoice / Billing from G2Academy</div>
            <ul class="text-base list-disc px-7">
              <li>Nama Pelajar</li>
              <li>Jumlah tagihan</li>
              <li>Nama program pendidikan</li>
              <li>Periode belajar</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="py-10 bg-white mdmax:px-4">
      <div class="container text-center">
        <div class="text-2xl font-semibold text-center mb-6">Proses Pengajuan ke Loan Partner (Pintek)</div>
        <img src="@/assets/images/snpl_process.png" alt="snpl_process" class="mx-auto rounded-lg mdmax:w-auto" />
      </div>
    </div>
    <div class="py-10 bg-grey mdmax:px-4">
      <div class="paylater__content--row--desc mdmax:flex mdmax:justify-center mdmax:flex-col items-center mt-10">
        <div class="paylater__content--row--desc--title text-2xl font-bold mb-1 mdmax:text-center text-center">Siap mengajukan pinjaman?</div>
        <div class="paylater__content--row--desc--detail mb-6 ml-4 text-center">Kami selalu siap untuk membantu anda.</div>
        <div class="paylater__content--row--desc--button text-center mb-14">
          <a class="text-white border border-yellow bg-yellow px-4 py-2 rounded font-semibold" href="loan/pintek">Contact Us</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Snpl',
  components: {
    ImageItem: () => import(/* webpackChunkName: "image" */ '@/components/image/ImageItem')
  }
}
</script>
<style lang="scss" scoped>
.paylater {
  &__header {
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    background-color: #ffffff;
  }
}
</style>
